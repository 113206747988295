class StswiperHandler extends elementorModules.frontend.handlers.Base {
  getDefaultSettings() {
    return {
      selectors: {
        slider: '.swiper-container',
        slide: '.swiper-slide',
        content: '.style_content',
      },
    };
  }

  getSlidesCount() {
    return this.elements.$slides.length;
  }
  getDefaultElements() {
    var elements = {
      $swiperContainer: this.$element.find(this.getSettings('selectors').slider)
    };
    elements.$slides = elements.$swiperContainer.find(this.getSettings('selectors').slide);
    return elements;
  }
  onInit() {
      elementorModules.frontend.handlers.Base.prototype.onInit.apply(this, arguments);
      this.initSlider();
  }

  initSlider() {
    var settings = this.getSettings(),
        elementSettings = this.getElementSettings();

    if (!this.elements.$swiperContainer.length) {
      return;
    }

    this.swiper = new Swiper(this.elements.$swiperContainer[0], this.getSwiperOptions());
  }

  getInitialSlide() {
    const editSettings = this.getEditSettings();

    return editSettings.activeItemIndex ? editSettings.activeItemIndex - 1 : 0;
  }

  getSwiperOptions() {
    var elementSettings = this.getElementSettings();
    var swiperOptions = {
      speed: elementSettings.slider_a_speed ? elementSettings.slider_a_speed : 400,
      loop: elementSettings.rewind_nav=='1' && this.getSlidesCount()>1,
      // grabCursor: ,
      autoHeight : elementSettings.auto_height=='1',
      slidesPerView : 1,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      initialSlide: this.getInitialSlide(),
      on: {
        slideChangeTransitionStart: function(swiper){
            if($(swiper.slides).filter('.swiper-slide-visible').length>0)
            {
                $.each(swiper.slides, function(){
                    if(!$(this).hasClass('swiper-slide-visible'))
                    {
                        var style_content = $(this).find('.style_content').removeClass('curr_swiper');
                        style_content.removeClass('animated '+style_content.data('animate'));
                        style_content.find('.layered_content').removeClass('animated '+style_content.data('animate'));
                    }
                });
            }
        },
        slideChangeTransitionEnd: function(swiper){
            var visible_slides = $(swiper.slides).filter('.swiper-slide-visible');
            $.each(visible_slides, function(){
                var style_content = $(this).find('.style_content');
                if(!style_content.hasClass('curr_swiper'))
                {
                    style_content.addClass('curr_swiper');
                    if(style_content.data('animate')){
                        if(style_content.find('.layered_content').length)
                            style_content.find('.layered_content').addClass('animated '+style_content.data('animate'));
                        else
                            style_content.addClass('animated '+style_content.data('animate'));
                    }
                }
            });
        },
        init: function (swiper) {
          if (elementSettings.direction_nav!='0'){
            if($(swiper.slides).length==$(swiper.slides).filter('.swiper-slide-visible').length)
            {
                $(swiper.params.navigation.nextEl).hide();
                $(swiper.params.navigation.prevEl).hide();
            }
            else
            {
                $(swiper.params.navigation.nextEl).show();
                $(swiper.params.navigation.prevEl).show();
            }
          }
          if($(swiper.slides).length==$(swiper.slides).filter('.swiper-slide-visible').length)//do this only when the number of items is the same as visible items.
          {
              var style_content = $(swiper.slides).find('.style_content').addClass('curr_swiper');

              $.each(style_content, function(){
                  if($(this).data('animate')){
                      if($(this).find('.layered_content').length)
                          $(this).find('.layered_content').addClass('animated '+$(this).data('animate'));
                      else
                          $(this).addClass('animated '+$(this).data('animate'));
                  }
              });
          }
          else if($(swiper.slides).filter('.swiper-slide-visible').length){
              var visible_slides = $(swiper.slides).filter('.swiper-slide-visible');
              $.each(visible_slides, function(){
                  var style_content = $(this).find('.style_content');
                  if(!style_content.hasClass('curr_swiper'))
                  {
                      style_content.addClass('curr_swiper');
                      if(style_content.data('animate')){
                          if(style_content.find('.layered_content').length)
                              style_content.find('.layered_content').addClass('animated '+style_content.data('animate'));
                          else
                              style_content.addClass('animated '+style_content.data('animate'));
                      }
                  }
              });
          }
        },
      },
      inviewwatcher:true
    };
    if(elementSettings.slider_slideshow!='0'){
      swiperOptions.autoplay = {
        delay: elementSettings.slider_s_speed ? elementSettings.slider_s_speed : 7000,
        stopOnLastSlide:elementSettings.slider_slideshow=='2',
        disableOnInteraction: elementSettings.slider_pause_on_hover=='1'
      };
    }
    
    if (elementSettings.lazy_load=='1'){
      swiperOptions.lazy = {
        loadPrevNext: true,
        loadPrevNextAmount: 1,
        loadOnTransitionStart: true
      };
    }
    if (elementSettings.direction_nav!='0'){
      swiperOptions.navigation = {
        nextEl: '#st_swiper_'+this.getID()+' .swiper-button-next',
        prevEl: '#st_swiper_'+this.getID()+' .swiper-button-prev'
      };
    }
    if (elementSettings.control_nav!='0'){
      swiperOptions.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        type: elementSettings.control_nav=='2' || elementSettings.control_nav=='4' ? 'bullets' : (elementSettings.control_nav=='3' ? 'progress' : 'bullets' ) //A bug of swiper, this should be 'custom' when nav==2
      }
      if (elementSettings.control_nav=='2')
          swiperOptions.pagination.renderBullet = function (index, className) {
              return '<span class="' + className + '">' + (index + 1) + '</span>';
          };
      else if (elementSettings.control_nav=='4')
          swiperOptions.pagination.renderBullet = function (index, className) {
              return '<span class="' + className + '"><span></span></span>';
          };
    }

    /*{if isset($column_slider) && $column_slider}
        observer : true,
        observeParents : true,
    {/if}*/

    /*{if $slides.progress_bar}
    custom_progress_bar: true,
    bar_time: {$slides.time|default:5000},
    {/if}*/
    if (elementSettings.transition_style=='1')
      swiperOptions = jQuery.extend(swiperOptions, {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        }
      });
    else if (elementSettings.transition_style=='2')
      swiperOptions = jQuery.extend(swiperOptions, {
        effect: 'cube',
        cubeEffect: {
          shadow: false, slideShadows: false
        }
      });
    else if (elementSettings.transition_style=='3')
      swiperOptions = jQuery.extend(swiperOptions, {
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 50, stretch: 0, depth: 100, modifier: 1, slideShadows : false
        }
      });
    else if (elementSettings.transition_style=='4')
      swiperOptions = jQuery.extend(swiperOptions, {
        effect: 'flip',
        flipEffect: {
          slideShadows : false, limitRotation: true
        }
      });
    return swiperOptions;
  }
  
  onEditSettingsChange(propertyName) {
    if (1 >= this.getSlidesCount()) {
      return;
    }
    if ('activeItemIndex' === propertyName) {
      this.swiper.slideToLoop(this.getEditSettings('activeItemIndex') - 1);
    }
  }
}

export default ( $scope ) => {
  elementorFrontend.elementsHandler.addHandler( StswiperHandler, { $element: $scope } );
};
